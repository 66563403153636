import { inject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-http-client';
import { Router } from 'aurelia-router';
import { Api } from 'services/api';
import { KaDialog, KaToast } from 'ka-components';
import { TableInterface, SearchInterface } from 'classes/md-table';

import mdt from 'services/md-tools';

@inject(Router, Api, KaDialog, KaToast)
export class vmAbbonamentiAttivi {
  mdt = mdt;
  statuses = {
    'ACTIVE': 'Attivo',
    'PENDING': 'In attesa di pagamento',
    'SUSPENDED': 'Sospeso',
    'EXPIRED': 'Scaduto',
    'TERMINATED': 'Terminato'
  }

  constructor(router, api, dialog, toast) {
    this.router = router;
    this.api = api;
    this.dialog = dialog;
    this.toast = toast;
  }

  activate(model) {
    model.tab.controller = this;
    this.tools = model.tools;
    this.parent = model.parent;
    this.params = model.params;
    this.privileges = model.privileges;
    this.requestedUserData = model.requestedUserData;
    this.lastAcceptedServiceContract = this.privileges.lastAcceptedServiceContract;
  }

  attached() {
    this.init();
  }

  setStatusDatasource() {
    const datasource = [{ value: 'null', text: 'Tutti' }]
    for (const [key, value] of Object.entries(this.statuses)) {
      datasource.push({ value: key, text: value });
    }
    return datasource;
  }

  async init() {
    await this.initTableInterface();
    await this.initSearchInterface();
    this.searchActiveUsersChange();

    this.productCodeChanged(this.searchInterface.data.productCode);

    if (!this.privileges.isOperator || (this.privileges.isOperator && this.searchInterface.data.user && this.searchInterface.data.context)) {
      this.search();
    }
  }

  initTableInterface() {
    this.tableInterface = new TableInterface({
      name: 'tab-abbonamenti-attivi-table',
      client: this.api,
      endpoint: null
    });

    this.tableInterface.parseResponse = (response) => {
      response.forEach((service) => {
        this.isServiceAutoRenewDisabled(service);
      });

      return response;
    };

    return this.tableInterface.initialize();
  }

  initSearchInterface() {
    this.searchInterface = new SearchInterface({
      name: 'tab-abbonamenti-attivi-search',
      table: this.tableInterface,
      schema: {
        context: {
          control: 'combo',
          label: 'Quali servizi attivi visualizzare?',
          datasource: [
            { value: 'USER', text: (this.privileges.isOperator ? 'I servizi attivi dell\'utente' : 'I miei servizi attivi') },
            { value: 'SUB-USER', text: (this.privileges.isOperator ? 'I servizi attivi dei sottoutenti' : 'I servizi attivi dei miei ' + this.mdt.parsers.contextualize(this.api.user, 'utenti')) }
          ],
          datavalue: 'value',
          datatext: 'text',
          datamultiple: false,
          datapreload: true,
          required: true
        },
        user: {
          control: 'combo',
          label: this.mdt.parsers.contextualize(this.api.user, 'Utente'),
          datasource: { table: `${this.privileges.isOperator ? 'bo/users' : 'users/me/users'}?include=user-profiles`, query: { search: 'text-search', item: 'id-in' } },
          datavalue: 'id',
          datatext: 'profile[0].displayName',
          datamultiple: false,
          datapreload: false,
          required: this.privileges.isOperator
        },
        user_active: {
          control: 'check',
          label: ' ',
          description: `Includi solo ${this.mdt.parsers.contextualize(this.api.user, 'utenti')} attivi`
        },
        flatServiceCode: {
          control: 'text',
          label: 'Codice servizio',
          query: 'flat-service-code-in'
        },
        status: {
          control: 'combo',
          label: 'Stato attivazione',
          datasource: this.setStatusDatasource(),
          datavalue: 'value',
          datatext: 'text',
          datapreload: true,
          query: 'status-in'
        },
        productCode: {
          control: 'combo',
          label: 'Prodotto',
          datasource: { table: 'products' },
          datavalue: 'code',
          datatext: 'label',
          datamultiple: false,
          datapreload: true,
          query: 'product-code-in'
        },
        type: {
          control: 'combo',
          label: 'Piano',
          datasource: { table: 'flat-service-types' },
          datavalue: 'code',
          datatext: 'description',
          datamultiple: false,
          datapreload: true,
          query: 'type-in'
        },
        cashbackAmount: {
          label: 'Cashback',
          control: 'combo',
          datasource: [
            { value: 'null', text: 'Qualsiasi' },
            { value: 'YES', text: 'Servizi con cashback' },
            { value: 'NO', text: 'Servizi senza cashback' }
          ],
          query: 'has-cashback'
        }
      },
      data: {
        status: 'null',
        user_active: true,
        cashbackAmount: 'null',
        context: !this.privileges.isOperator ? 'SUB-USER' : null
      }
    });
    return this.searchInterface.initialize();
  }

  searchActiveUsersChange() {
    return this.searchInterface.schema.user.datasource.table = `${this.privileges.isOperator ? 'bo/users' : 'users/me/users'}?${this.searchInterface.data.user_active ? 'status=ACTIVE&' : ''}include=user-profiles`;
  }

  productCodeChanged(value) {
    // Control "type" related to "productCode"
    if (this.searchInterface.controls?.type) {
      this.searchInterface.data.type = null;
      this.searchInterface.controls.type.datasource.table = value ? `flat-service-types?product-code-in=${value}` : 'flat-service-types';
      this.searchInterface.controls.type.comboItemsSource = null;
      this.searchInterface.controls.type.comboItems = null;
    } else {
      this.searchInterface.schema.type.datasource.table = value ? `flat-service-types?product-code-in=${value}` : 'flat-service-types';
    }
  }

  search() {
    this.updateTableInterfaceEndpoint();
    this.updateTableInterfaceQuery();
    this.searchInterface.search();
  }

  updateTableInterfaceEndpoint() {
    // Base endpoint
    let endpoint = 'users';
    // users/(me|{id})
    if (this.requestedUserData) endpoint += `/${this.requestedUserData.id}`;
    else if (this.searchInterface.data.context === 'USER' && !this.privileges.isOperator) endpoint += '/me';
    else if (this.searchInterface.data.context === 'USER' && this.privileges.isOperator) endpoint += `/${this.searchInterface.data.user}`;
    else if (this.searchInterface.data.context === 'SUB-USER' && this.searchInterface.data.user) endpoint += `/${this.searchInterface.data.user}`;
    else if (this.searchInterface.data.context === 'SUB-USER' && !this.privileges.isOperator && !this.searchInterface.data.user) endpoint += '/me';
    // users/(me|{id})/(subusers?)
    if (this.requestedUserData && this.searchInterface.data.context === 'SUB-USER' && this.privileges.isOperator) endpoint += '/subusers';
    else if (!this.requestedUserData && this.searchInterface.data.context === 'SUB-USER' && !this.privileges.isOperator && !this.searchInterface.data.user) endpoint += '/subusers';
    else if (!this.requestedUserData && this.searchInterface.data.context === 'SUB-USER' && this.privileges.isOperator) endpoint += '/subusers';
    // users/(me|{id})/(subusers?)/flat-services-activations
    endpoint += '/flat-services-activations';
    return this.tableInterface.endpoint = endpoint;
  }
  updateTableInterfaceQuery() {
    const isOperatorActiveSubusersWorthy = this.privileges.isOperator && this.searchInterface.data.context === 'SUB-USER' && this.searchInterface.data.user_active;
    const isUserActiveSubusersWorthy = !this.privileges.isOperator && this.searchInterface.data.context === 'SUB-USER' && !this.searchInterface.data.user && this.searchInterface.data.user_active;
    const queryParams = [];
    if (this.privileges.isMaster) queryParams.push('acknowledged=YES');
    if (isOperatorActiveSubusersWorthy || isUserActiveSubusersWorthy) queryParams.push('owner-status-in=ACTIVE');
    return this.tableInterface.query = queryParams.join('&') || null;
  }

  reset() {
    this.tableInterface.reset();
    this.searchInterface.reset(true);
    this.searchInterface.data.status = 'null';
    if (!this.privileges.isOperator && !this.requestedUserData) this.searchInterface.data.context = 'SUB-USER';
    if (!this.privileges.isOperator) this.search();
  }

  showLogs(item) {
    this.dialog.open({
      title: 'Elenco log',
      class: 'large',
      viewModel: PLATFORM.moduleName('views/servizi/abbonamenti/dialogs/abbonamenti-log'),
      viewModelParams: item
    }, true);
  }

  showBankTransfer(item) {
    const legacyApi = new HttpClient();
    legacyApi.configure(client => {
      client.withBaseUrl(`${ENVIRONMENT.APP_URL_LEGACY}api/`);
      client.withHeader('Accept', 'application/json');
      client.withHeader('Authorization', this.api.accessToken);
    });
    legacyApi.get(`getPaymentDetail.php?order-id=${item.orderId}`).then(xhr => {
      let response = JSON.parse(xhr.response);
      this.dialog.open({
        title: 'Dettagli per il pagamento mezzo bonifico',
        type: 'alert',
        class: 'small',
        body: `Per completare l'attivazione del servizio è necessario effettuare il versamento di <strong>${response.totalAmount}€</strong> sul conto corrente:<br><br>
        BENEFICIARIO: ${response.creditorCompany}<br>
        IBAN: ${response.creditorIban}<br>
        CAUSALE: ${response.reason}<br>
        IMPORTO: ${response.totalAmount}<br><br>
        Il tuo ordine sarà confermato non appena riceveremo il bonifico. L'operazione potrebbe richiedere qualche giorno.`
      }, true);
    });

  }

  editExpiration($event, item) {
    if ($event.model) $event.model.busy = true;
    this.dialog.open({
      title: 'Modifica scadenza servizio',
      class: 'small',
      viewModel: PLATFORM.moduleName('views/servizi/abbonamenti/dialogs/abbonamenti-scadenza'),
      viewModelParams: { service: item }
    }, true).whenClosed(() => {
      this.search();
      if ($event.model) $event.model.busy = false;
    });
  }

  editStatus($event, action, item) {
    let data = null;
    if (action === 'sospendi') data = { status: 'SUSPENDED' };
    if (action === 'riattiva') data = { status: 'ACTIVE' };
    if (action === 'termina') data = { status: 'TERMINATED' };
    if (data) {
      $event.model.busy = true;
      this.dialog.open({
        title: `${action.charAt(0).toUpperCase() + action.slice(1)} servizio`,
        class: 'small abbonamenti-stato-servizio',
        viewModel: PLATFORM.moduleName('views/servizi/abbonamenti/dialogs/abbonamenti-stato-servizio'),
        viewModelParams: { service: item, status: data.status }
      }, true).whenClosed(() => {
        this.search();
        $event.model.busy = false;
      });
    }
  }

  dialogAutoRenew($event, service) {
    this.dialog.open({
      viewModelParams: service,
      class: 'small abbonamenti-autorinnovo',
      title: (service.autoRenew === 'ENABLED' ? 'Disattiva autorinnovo' : 'Attiva autorinnovo'),
      viewModel: PLATFORM.moduleName('views/servizi/abbonamenti/dialogs/abbonamenti-autorinnovo')
    }).whenClosed((response) => {
      if (!response.wasCancelled) {
        let data = { autoRenew: service.autoRenew === 'ENABLED' ? 'DISABLED' : 'ENABLED' };
        if (service.autoRenew === 'DISABLED') data.autoRenewPaymentMethod = response.output.autoRenewPaymentMethod;

        this.api.patch(`users/${service.ownerId || 'me'}/flat-services-activations/${service.id}`, data).then(() => {
          this.toast.show(`Autorinnovo ${service.autoRenew === 'DISABLED' ? 'attivato' : 'disattivato'} con successo!`, 'success');
          this.search();
        }).catch(error => {
          this.api.dialogError(error);
        }).finally(() => { $event.model.busy = false; });
      }
      $event.model.busy = false;
    });
  }

  editRenewal($event, service) {
    $event.model.busy = true;
    if (service.autoRenew === 'DISABLED' && (this.lastAcceptedServiceContract?.gracePeriodEndAt || this.lastAcceptedServiceContract?.rescindedAt) && service.flatServiceCashbackAmount) {
      this.tools.dialogCashbackSubscriptionRequired($event, 'autoRenew');
    } else {
      this.dialogAutoRenew($event, service);
    }
  }

  renew($event, item) {
    $event.model.busy = true;
    let data = {
      autoRenew: item.autoRenew,
      flatServiceUuid: item.flatServiceUuid,
      payeeUserUuid: item.payeeUserUuid,
      payeeBillingProfileUuid: item.payeeBillingProfileUuid,
      paymentMethod: item.paymentMethod
    }
    this.api.post(`users/${item.ownerId || 'me'}/flat-services-activations/${item.id}/renewals`, data).then(() => {
      this.toast.show(`Servizio rinnovato con successo!`, 'success');
      this.search();
    }).catch(x => {
      this.api.dialogError(x);
    }).finally(() => { $event.model.busy = false; });
  }

  serviceDescription(description) {
    this.dialog.open({ title: 'Descrizione servizio', class: 'medium', type: 'alert', body: `${description}` });
  }

  csvDownload() {
    this.toast.show('Caricamento in corso', 'loading', true);

    // Uri build
    let query = '';
    if (this.searchInterface?.table?._query) query = `?${decodeURIComponent(this.searchInterface.table._query)}`;
    let path = `${this.tableInterface.endpoint}.csv`;
    let uri = `${path}${query}`;

    this.api.client.createRequest(uri)
      .asGet()
      .withHeader('Accept', 'application/csv')
      .withResponseType('blob')
      .send()
      .then(xhr => {
        // Filename
        let regexp = new RegExp('filename=(.+\.csv$)');
        let matches = xhr.headers.headers['content-disposition'].value.match(regexp);

        let anchor = document.createElement('a');
        let blob = new Blob([xhr.response], { type: 'text/csv' });
        let url = URL.createObjectURL(blob);
        anchor.href = url;
        anchor.download = matches[1];
        document.body.appendChild(anchor);
        anchor.click();
        document.body.removeChild(anchor);
      }).catch(xhr => {
        console.log('ERROR - flat-services-activations.csv download - GET', xhr);
      }).finally(() => {
        this.toast.consume();
      });
  }

  isServiceAutoRenewDisabled(service) {
    service.isAutoRenewDisabled = false; //this should be setted to false by default otherwise all buttons will be disabled because isAutoRenewDisabled = undefined;
    const currentDate = new Date();

    //I apply this check only in case of autoRenew === 'ENABLED'
    if (service.autoRenew === 'ENABLED') {
      const expireAt = new Date(service.expireAt); //expireAt date should be parsed as Date object otherwise addition / substraction ops on date couldn't be done;
      const condition = expireAt.setDate(expireAt.getDate() - 30); //condition = the epic asked that button should be disabled if expireAt is lower tha 30 days
      service.isAutoRenewDisabled = condition < currentDate; //so if expireAt - 30 days > today it means that expireAt is greater than 30 days so btn will not be disabled
    }

    return service;
  }

  /* dialogServiceDetail(service) {
    this.dialog.open({
      class: 'large abbonamenti-detail',
      title: 'Dettaglio abbonamento',
      viewModelParams: { ownerId: service.ownerId, flatServiceUuid: service.id },
      viewModel: PLATFORM.moduleName('views/servizi/abbonamenti/dialogs/abbonamenti-detail'),
    });
  } */
}
